import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID, TransferState, makeStateKey } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class EnvironmentService {
  constructor(
    private state: TransferState,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  get isServer() {
    return isPlatformServer(this.platformId);
  }

  get useHubConnections() {
    return environment.useHubConnections;
  }

  getValueTransferState(key: string) {
    switch (key) {
      case 'API_URL':
        return environment.API_URL;
      
      case 'HUB_URL':
        return environment.HUB_URL;
      
      case 'HUB_WORKER_URL':
        return environment.HUB_WORKER_URL;
      
      case 'IDENTITY_URL':
        return environment.IDENTITY_URL;
    
      default:
        return;
    }
    // const stateKey = makeStateKey(key);

    // let value = this.state.get(stateKey, null as any);
    // // try to get value on enviroment
    // if (!value) {
    //   value = environment[key];
    // }
    // return value;
  }
  setValueTransferState(key: string, payload: any) {
    const stateKey = makeStateKey(key);
    this.state.set(stateKey, payload);
  }
}
