{
  "name": "versiant-front",
  "version": "6.1.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "serve:ssr": "node dist/versiant-front/server/server.mjs",
    "start:multitenant": "ng serve --host maritimeinformationsystem.bz --port 4200 --disable-host-check true",
    "start:local-api:multitenant": "ng serve -c=local-api --host maritimeinformationsystem.bz --port 4200 --disable-host-check true",
    "build": "ng build",
    "build:ssr:dev": "ng build -c=dev",
    "build:ssr:qa": "ng build -c=qa",
    "build:ssr:prod": "ng build -c=production",
    "build:ssr:rhk": "ng build -c=rhk",
    "test": "jest --config ./jest.config.js",
    "lint": "ng lint",
    "prerender": "ng run versiant-front:prerender",
    "update:version": "npm --no-git-tag-version version",
    "prepare": "husky install"
  },
  "prettier": {
    "printWidth": 100,
    "semi": true,
    "trailingComma": "none",
    "arrowParens": "avoid"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.10",
    "@angular/cdk": "17.3.10",
    "@angular/common": "^17.3.10",
    "@angular/compiler": "^17.3.10",
    "@angular/core": "^17.3.10",
    "@angular/forms": "^17.3.10",
    "@angular/localize": "^17.3.10",
    "@angular/material": "17.3.10",
    "@angular/platform-browser": "^17.3.10",
    "@angular/platform-browser-dynamic": "^17.3.10",
    "@angular/platform-server": "^17.3.10",
    "@angular/router": "^17.3.10",
    "@angular/ssr": "^17.3.8",
    "@auth0/angular-jwt": "^5.0.2",
    "@microsoft/signalr": "^3.1.8",
    "@ngxs/logger-plugin": "^3.8.1",
    "@ngxs/store": "^3.8.1",
    "@twilio/voice-sdk": "^2.11.0",
    "express": "^4.15.2",
    "helmet": "^3.21.3",
    "moment": "^2.29.0",
    "ngx-image-cropper": "^7.0.2",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-mask": "^16.2.5",
    "powerbi-client": "^2.18.0",
    "rxjs": "~6.5.4",
    "tslib": "^2.3.1",
    "util": "^0.12.4",
    "uuid": "^8.3.2",
    "zone.js": "~0.14.6"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "8.4.1",
    "@angular-builders/jest": "^17.0.3",
    "@angular-devkit/architect": "^0.1703.8",
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.10",
    "@angular/language-service": "^17.3.10",
    "@fullhuman/postcss-purgecss": "^4.1.3",
    "@ngxs/devtools-plugin": "^3.8.1",
    "@testing-library/angular": "^16.0.0",
    "@testing-library/jest-dom": "^6.4.5",
    "@testing-library/user-event": "^14.5.2",
    "@types/express": "^4.17.0",
    "@types/jest": "^28.1.6",
    "@types/node": "^12.20.55",
    "@types/uuid": "^8.3.0",
    "autoprefixer": "^10.4.0",
    "browser-sync": "^3.0.0",
    "codelyzer": "^6.0.0",
    "husky": "^8.0.2",
    "jest": "^28.1.3",
    "jest-preset-angular": "^14.1.0",
    "postcss": "^8.4.4",
    "postcss-import": "^12.0.1",
    "postcss-loader": "^4.3.0",
    "postcss-scss": "^3.0.5",
    "tailwindcss": "^2.2.19",
    "ts-jest": "^28.0.7",
    "ts-node": "~8.3.0",
    "typescript": "^5.4.4"
  }
}
